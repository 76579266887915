/* ❗ Generated via scripts/generate-css.ts */

:root {
    /* variables. see @pi/theme --> src/variables.ts */
    --font-size: 14px;
    --z-index-nav: 10;
    --z-index-max: 2147483647;
    --z-index-popup: 2000000000;
    
    /* color variables. see @pi/theme --> src/colors.ts */
    --bp-black: #111418;
    --bp-dark-gray1: #1c2127;
    --bp-dark-gray2: #252a31;
    --bp-dark-gray3: #2f343c;
    --bp-dark-gray4: #383e47;
    --bp-dark-gray5: #404854;
    --bp-gray1: #5f6b7c;
    --bp-gray2: #738091;
    --bp-gray3: #8f99a8;
    --bp-gray4: #abb3bf;
    --bp-gray5: #c5cbd3;
    --bp-light-gray1: #d3d8de;
    --bp-light-gray2: #dce0e5;
    --bp-light-gray3: #e5e8eb;
    --bp-light-gray4: #edeff2;
    --bp-light-gray5: #f6f7f9;
    --bp-white: #ffffff;
    --bp-blue1: #184a90;
    --bp-blue2: #215db0;
    --bp-blue3: #2d72d2;
    --bp-blue4: #4c90f0;
    --bp-blue5: #8abbff;
    --bp-green1: #165a36;
    --bp-green2: #1c6e42;
    --bp-green3: #238551;
    --bp-green4: #32a467;
    --bp-green5: #72ca9b;
    --bp-orange1: #77450d;
    --bp-orange2: #935610;
    --bp-orange3: #c87619;
    --bp-orange4: #ec9a3c;
    --bp-orange5: #fbb360;
    --bp-red1: #8e292c;
    --bp-red2: #ac2f33;
    --bp-red3: #cd4246;
    --bp-red4: #e76a6e;
    --bp-red5: #fa999c;
    --bp-vermilion1: #96290d;
    --bp-vermilion2: #b83211;
    --bp-vermilion3: #d33d17;
    --bp-vermilion4: #eb6847;
    --bp-vermilion5: #ff9980;
    --bp-rose1: #a82255;
    --bp-rose2: #c22762;
    --bp-rose3: #db2c6f;
    --bp-rose4: #f5498b;
    --bp-rose5: #ff66a1;
    --bp-violet1: #5c255c;
    --bp-violet2: #7c327c;
    --bp-violet3: #9d3f9d;
    --bp-violet4: #bd6bbd;
    --bp-violet5: #d69fd6;
    --bp-indigo1: #5642a6;
    --bp-indigo2: #634dbf;
    --bp-indigo3: #7961db;
    --bp-indigo4: #9881f3;
    --bp-indigo5: #bdadff;
    --bp-cerulean1: #1f4b99;
    --bp-cerulean2: #2458b3;
    --bp-cerulean3: #2965cc;
    --bp-cerulean4: #4580e6;
    --bp-cerulean5: #669eff;
    --bp-turquoise1: #004d46;
    --bp-turquoise2: #007067;
    --bp-turquoise3: #00a396;
    --bp-turquoise4: #13c9ba;
    --bp-turquoise5: #7ae1d8;
    --bp-forest1: #1d7324;
    --bp-forest2: #238c2c;
    --bp-forest3: #29a634;
    --bp-forest4: #43bf4d;
    --bp-forest5: #62d96b;
    --bp-lime1: #43501b;
    --bp-lime2: #5a701a;
    --bp-lime3: #8eb125;
    --bp-lime4: #b6d94c;
    --bp-lime5: #d4f17e;
    --bp-gold1: #5c4405;
    --bp-gold2: #866103;
    --bp-gold3: #d1980b;
    --bp-gold4: #f0b726;
    --bp-gold5: #fbd065;
    --bp-sepia1: #5e4123;
    --bp-sepia2: #7a542e;
    --bp-sepia3: #946638;
    --bp-sepia4: #af855a;
    --bp-sepia5: #d0b090;
    --bp-pt-intent-primary: #2d72d2;
    --bp-pt-intent-success: #238551;
    --bp-pt-intent-warning: #c87619;
    --bp-pt-intent-danger: #cd4246;
    --bp-pt-app-background-color: #f6f7f9;
    --bp-pt-dark-app-background-color: #2f343c;
    --bp-pt-outline-color: rgba(45, 114, 210, 0.6);
    --bp-pt-text-color: #1c2127;
    --bp-pt-text-color-muted: #5f6b7c;
    --bp-pt-text-color-disabled: rgba(95, 107, 124, 0.6);
    --bp-pt-heading-color: #1c2127;
    --bp-pt-link-color: #215db0;
    --bp-pt-dark-text-color: #f6f7f9;
    --bp-pt-dark-text-color-muted: #abb3bf;
    --bp-pt-dark-link-color: #8abbff;
    --bp-pt-text-selection-color: rgba(125, 188, 255, .6);
    --bp-pt-dark-text-color-disabled: rgba(171, 179, 191, 0.6);
    --bp-pt-dark-heading-color: #f6f7f9;
    --bp-pt-icon-color: #5f6b7c;
    --bp-pt-icon-color-hover: #1c2127;
    --bp-pt-icon-color-disabled: rgba(95, 107, 124, 0.6);
    --bp-pt-icon-color-selected: #2d72d2;
    --bp-pt-dark-icon-color: #abb3bf;
    --bp-pt-dark-icon-color-hover: #f6f7f9;
    --bp-pt-dark-icon-color-disabled: rgba(171, 179, 191, 0.6);
    --bp-pt-dark-icon-color-selected: #2d72d2;
    --bp-pt-divider-black: rgba(17, 20, 24, 0.15);
    --bp-pt-dark-divider-black: rgba(17, 20, 24, 0.4);
    --bp-pt-dark-divider-white: rgba(255, 255, 255, 0.15);
    --bp-pt-code-text-color: #5f6b7c;
    --bp-pt-dark-code-text-color: #abb3bf;
    --bp-pt-code-background-color: rgba(255, 255, 255, 0.7);
    --bp-pt-dark-code-background-color: rgba(17, 20, 24, 0.3);
    --border-color: #c5cbd3;
    --menu-background-color: #ffffff;
    --link-color: #4c90f0;
    --nav-background-color: #ffffff;
    --error-text-color: #ac2f33;
}

.bp3-dark, .bp4-dark {
    /* dark model color variables. see @pi/theme --> src/colors.ts */
    --border-color: #5f6b7c;
    --menu-background-color: #8f99a8;
    --nav-background-color: #383e47;
    --error-text-color: #fa999c;
    --bp-pt-app-background-color: #2f343c;
    --bp-pt-text-color: #f6f7f9;
    --bp-pt-text-color-muted: #abb3bf;
    --bp-pt-text-color-disabled: rgba(171, 179, 191, 0.6);
    --bp-pt-heading-color: #f6f7f9;
    --bp-pt-link-color: #8abbff;
    --bp-pt-icon-color: #abb3bf;
    --bp-pt-icon-color-hover: #f6f7f9;
    --bp-pt-icon-color-disabled: rgba(171, 179, 191, 0.6);
    --bp-pt-icon-color-selected: #2d72d2;
    --bp-pt-divider-black: rgba(17, 20, 24, 0.4);
    --bp-pt-divider-white: rgba(255, 255, 255, 0.15);
    --bp-pt-code-text-color: #abb3bf;
    --bp-pt-code-background-color: rgba(17, 20, 24, 0.3);
}

/* included custom css. see @pi/theme --> src/global.css */
* { box-sizing: border-box; }

body {
    font-size: var(--font-size);
    line-height: var(--font-size);
    color: var(--bp-pt-text-color);
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a:hover {
    text-decoration: none !important;
}

textarea {
    font-family: inherit;
}

/**** BlueprintJS overrides ****/

.bp4-dialog{
    width: unset;
    min-width: 30rem;
    max-width: 40rem;
}

.bp4-control, .bp4-control.bp4-switch {
    margin-bottom: unset;
}

.bp4-dialog-footer-actions, .bp4-button:first-child {
    margin-left: unset;
}

.bp4-popover2-content > .bp4-menu {
    max-height: 20rem;
    overflow-y: auto;
}

.bp4-html-table > tbody:first-child > tr:first-child > td {
    box-shadow: none; /* annoying top border when you don't use a header for a table */
}


/* standard spacing classes. see @pi/theme --> scripts/generate-cs.ts --> generateSpacingCSS() */
.mt0 { margin-top: 0rem!important; }
.mr0 { margin-right: 0rem!important; }
.mb0 { margin-bottom: 0rem!important; }
.ml0 { margin-left: 0rem!important; }
.m0 { margin: 0rem 0rem 0rem 0rem!important; }
.mx0 { margin-left: 0rem!important; margin-right: 0rem!important; }
.my0 { margin-top: 0rem!important; margin-bottom: 0rem!important; }
.mt1 { margin-top: 1rem!important; }
.mr1 { margin-right: 1rem!important; }
.mb1 { margin-bottom: 1rem!important; }
.ml1 { margin-left: 1rem!important; }
.m1 { margin: 1rem 1rem 1rem 1rem!important; }
.mx1 { margin-left: 1rem!important; margin-right: 1rem!important; }
.my1 { margin-top: 1rem!important; margin-bottom: 1rem!important; }
.mt2 { margin-top: 2rem!important; }
.mr2 { margin-right: 2rem!important; }
.mb2 { margin-bottom: 2rem!important; }
.ml2 { margin-left: 2rem!important; }
.m2 { margin: 2rem 2rem 2rem 2rem!important; }
.mx2 { margin-left: 2rem!important; margin-right: 2rem!important; }
.my2 { margin-top: 2rem!important; margin-bottom: 2rem!important; }
.mt025 { margin-top: 0.25rem!important; }
.mr025 { margin-right: 0.25rem!important; }
.mb025 { margin-bottom: 0.25rem!important; }
.ml025 { margin-left: 0.25rem!important; }
.m025 { margin: 0.25rem 0.25rem 0.25rem 0.25rem!important; }
.mx025 { margin-left: 0.25rem!important; margin-right: 0.25rem!important; }
.my025 { margin-top: 0.25rem!important; margin-bottom: 0.25rem!important; }
.mt05 { margin-top: 0.5rem!important; }
.mr05 { margin-right: 0.5rem!important; }
.mb05 { margin-bottom: 0.5rem!important; }
.ml05 { margin-left: 0.5rem!important; }
.m05 { margin: 0.5rem 0.5rem 0.5rem 0.5rem!important; }
.mx05 { margin-left: 0.5rem!important; margin-right: 0.5rem!important; }
.my05 { margin-top: 0.5rem!important; margin-bottom: 0.5rem!important; }
.pt0 { padding-top: 0rem!important; }
.pr0 { padding-right: 0rem!important; }
.pb0 { padding-bottom: 0rem!important; }
.pl0 { padding-left: 0rem!important; }
.p0 { padding: 0rem 0rem 0rem 0rem!important; }
.px0 { padding-left: 0rem!important; padding-right: 0rem!important; }
.py0 { padding-top: 0rem!important; padding-bottom: 0rem!important; }
.pt1 { padding-top: 1rem!important; }
.pr1 { padding-right: 1rem!important; }
.pb1 { padding-bottom: 1rem!important; }
.pl1 { padding-left: 1rem!important; }
.p1 { padding: 1rem 1rem 1rem 1rem!important; }
.px1 { padding-left: 1rem!important; padding-right: 1rem!important; }
.py1 { padding-top: 1rem!important; padding-bottom: 1rem!important; }
.pt2 { padding-top: 2rem!important; }
.pr2 { padding-right: 2rem!important; }
.pb2 { padding-bottom: 2rem!important; }
.pl2 { padding-left: 2rem!important; }
.p2 { padding: 2rem 2rem 2rem 2rem!important; }
.px2 { padding-left: 2rem!important; padding-right: 2rem!important; }
.py2 { padding-top: 2rem!important; padding-bottom: 2rem!important; }
.pt025 { padding-top: 0.25rem!important; }
.pr025 { padding-right: 0.25rem!important; }
.pb025 { padding-bottom: 0.25rem!important; }
.pl025 { padding-left: 0.25rem!important; }
.p025 { padding: 0.25rem 0.25rem 0.25rem 0.25rem!important; }
.px025 { padding-left: 0.25rem!important; padding-right: 0.25rem!important; }
.py025 { padding-top: 0.25rem!important; padding-bottom: 0.25rem!important; }
.pt05 { padding-top: 0.5rem!important; }
.pr05 { padding-right: 0.5rem!important; }
.pb05 { padding-bottom: 0.5rem!important; }
.pl05 { padding-left: 0.5rem!important; }
.p05 { padding: 0.5rem 0.5rem 0.5rem 0.5rem!important; }
.px05 { padding-left: 0.5rem!important; padding-right: 0.5rem!important; }
.py05 { padding-top: 0.5rem!important; padding-bottom: 0.5rem!important; }